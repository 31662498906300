// Generated by Framer (1a71db7)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, ResolveLinks, SVG, useComponentViewport, useLocaleInfo, useRouter, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import TableOfContentNobs from "./okBtqeVDP";
const TableOfContentNobsFonts = getFonts(TableOfContentNobs);

const serializationHash = "framer-gEjQX"

const variantClassNames = {hrtDePPZP: "framer-v-g59mx2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "hrtDePPZP", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const router = useRouter()

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-g59mx2", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"hrtDePPZP"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-17xajw8"} layout={"position"} layoutDependency={layoutDependency} layoutId={"ggcfZkeJq-shape"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 31 565\"><path d=\"M 15.5 0 C 23.784 0 30.5 6.723 30.5 15.017 C 30.5 23.31 23.784 30.034 15.5 30.034 C 7.216 30.034 0.5 23.31 0.5 15.017 C 0.5 6.723 7.216 0 15.5 0 Z\" fill=\"rgb(0, 0, 0)\"></path><path d=\"M 15.5 533.966 C 23.784 533.966 30.5 540.69 30.5 548.983 C 30.5 557.277 23.784 564 15.5 564 C 7.216 564 0.5 557.277 0.5 548.983 C 0.5 540.69 7.216 533.966 15.5 533.966 Z\" fill=\"rgb(0, 0, 0)\"></path><path d=\"M 15.5 15 L 15.5 549\" fill=\"transparent\" stroke=\"rgb(0, 0, 0)\"></path></svg>"} svgContentId={12141477124} withExternalLayout/><ResolveLinks links={[{href: {hash: ":y_IkK8DFy", webPageId: "X4Bizc1cX"}, implicitPathVariables: undefined}]}>{(resolvedLinks) => (
<ComponentViewportProvider height={48} width={"88px"} y={((componentViewport?.y || 0) + 40)}><motion.div className={"framer-x16pe-container"} layoutDependency={layoutDependency} layoutId={"MwBQAEa6J-container"}><TableOfContentNobs BRZlA2eyV={"09.25"} f4RjawSzK={""} height={"100%"} id={"MwBQAEa6J"} layoutId={"MwBQAEa6J"} style={{height: "100%", width: "100%"}} tyx0iHIq8={resolvedLinks[0]} variant={"CPfTNerE3"} width={"100%"}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks><ResolveLinks links={[{href: {hash: ":MyxxwgqGj", webPageId: "X4Bizc1cX"}, implicitPathVariables: undefined}]}>{(resolvedLinks1) => (
<ComponentViewportProvider height={48} width={"88px"} y={((componentViewport?.y || 0) + 96)}><motion.div className={"framer-pxxjk1-container"} layoutDependency={layoutDependency} layoutId={"JqFxqpBlP-container"}><TableOfContentNobs BRZlA2eyV={"10.22"} f4RjawSzK={""} height={"100%"} id={"JqFxqpBlP"} layoutId={"JqFxqpBlP"} style={{height: "100%", width: "100%"}} tyx0iHIq8={resolvedLinks1[0]} variant={"CPfTNerE3"} width={"100%"}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks><ResolveLinks links={[{href: {hash: ":Y2YcaYwRG", webPageId: "X4Bizc1cX"}, implicitPathVariables: undefined}]}>{(resolvedLinks2) => (
<ComponentViewportProvider height={48} width={"88px"} y={((componentViewport?.y || 0) + 153)}><motion.div className={"framer-1kxvien-container"} layoutDependency={layoutDependency} layoutId={"Aqk7aSqfR-container"}><TableOfContentNobs BRZlA2eyV={"10.26"} f4RjawSzK={""} height={"100%"} id={"Aqk7aSqfR"} layoutId={"Aqk7aSqfR"} style={{height: "100%", width: "100%"}} tyx0iHIq8={resolvedLinks2[0]} variant={"CPfTNerE3"} width={"100%"}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks><ResolveLinks links={[{href: {hash: ":W4S0TbcDc", webPageId: "X4Bizc1cX"}, implicitPathVariables: undefined}]}>{(resolvedLinks3) => (
<ComponentViewportProvider height={49} width={"88px"} y={((componentViewport?.y || 0) + 209)}><motion.div className={"framer-vo3gnc-container"} layoutDependency={layoutDependency} layoutId={"IUU1qiHcI-container"}><TableOfContentNobs BRZlA2eyV={"10.29"} f4RjawSzK={""} height={"100%"} id={"IUU1qiHcI"} layoutId={"IUU1qiHcI"} style={{height: "100%", width: "100%"}} tyx0iHIq8={resolvedLinks3[0]} variant={"CPfTNerE3"} width={"100%"}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks><ResolveLinks links={[{href: {hash: ":HM636UnCP", webPageId: "X4Bizc1cX"}, implicitPathVariables: undefined}]}>{(resolvedLinks4) => (
<ComponentViewportProvider height={49} width={"88px"} y={((componentViewport?.y || 0) + (((componentViewport?.height || 578) * 0.5017301038062286) - 24.5))}><motion.div className={"framer-vt6v17-container"} layoutDependency={layoutDependency} layoutId={"fvnf6Ii2R-container"}><TableOfContentNobs BRZlA2eyV={"10.30"} f4RjawSzK={""} height={"100%"} id={"fvnf6Ii2R"} layoutId={"fvnf6Ii2R"} style={{height: "100%", width: "100%"}} tyx0iHIq8={resolvedLinks4[0]} variant={"CPfTNerE3"} width={"100%"}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks><ResolveLinks links={[{href: {hash: ":EqOm1mk60", webPageId: "X4Bizc1cX"}, implicitPathVariables: undefined}]}>{(resolvedLinks5) => (
<ComponentViewportProvider height={49} width={"87px"} y={(((componentViewport?.y || 0) + (componentViewport?.height || 578)) - 255)}><motion.div className={"framer-1v9xnxk-container"} layoutDependency={layoutDependency} layoutId={"bRidJPDsx-container"}><TableOfContentNobs BRZlA2eyV={"11.04"} f4RjawSzK={""} height={"100%"} id={"bRidJPDsx"} layoutId={"bRidJPDsx"} style={{height: "100%", width: "100%"}} tyx0iHIq8={resolvedLinks5[0]} variant={"CPfTNerE3"} width={"100%"}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks><ResolveLinks links={[{href: {hash: ":UYsUxLIkd", webPageId: "X4Bizc1cX"}, implicitPathVariables: undefined}]}>{(resolvedLinks6) => (
<ComponentViewportProvider height={49} width={"87px"} y={(((componentViewport?.y || 0) + (componentViewport?.height || 578)) - 197)}><motion.div className={"framer-g1ncvh-container"} layoutDependency={layoutDependency} layoutId={"bo4NUbPM6-container"}><TableOfContentNobs BRZlA2eyV={"11.20"} f4RjawSzK={""} height={"100%"} id={"bo4NUbPM6"} layoutId={"bo4NUbPM6"} style={{height: "100%", width: "100%"}} tyx0iHIq8={resolvedLinks6[0]} variant={"CPfTNerE3"} width={"100%"}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks><ResolveLinks links={[{href: {hash: ":ajfBZuODy", webPageId: "X4Bizc1cX"}, implicitPathVariables: undefined}]}>{(resolvedLinks7) => (
<ComponentViewportProvider height={48} width={"88px"} y={(((componentViewport?.y || 0) + (componentViewport?.height || 578)) - 140)}><motion.div className={"framer-fesag0-container"} layoutDependency={layoutDependency} layoutId={"erU0niPpm-container"}><TableOfContentNobs BRZlA2eyV={"12.04"} f4RjawSzK={""} height={"100%"} id={"erU0niPpm"} layoutId={"erU0niPpm"} style={{height: "100%", width: "100%"}} tyx0iHIq8={resolvedLinks7[0]} variant={"CPfTNerE3"} width={"100%"}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks><ResolveLinks links={[{href: {hash: ":jVcGF8_7x", webPageId: "X4Bizc1cX"}, implicitPathVariables: undefined}]}>{(resolvedLinks8) => (
<ComponentViewportProvider height={48} width={"87px"} y={(((componentViewport?.y || 0) + (componentViewport?.height || 578)) - 84)}><motion.div className={"framer-197h5e0-container"} layoutDependency={layoutDependency} layoutId={"rrMHnU9OW-container"}><TableOfContentNobs BRZlA2eyV={"12.10"} f4RjawSzK={""} height={"100%"} id={"rrMHnU9OW"} layoutId={"rrMHnU9OW"} style={{height: "100%", width: "100%"}} tyx0iHIq8={resolvedLinks8[0]} variant={"CPfTNerE3"} width={"100%"}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gEjQX.framer-hf5rvi, .framer-gEjQX .framer-hf5rvi { display: block; }", ".framer-gEjQX.framer-g59mx2 { height: 578px; position: relative; width: 166px; }", ".framer-gEjQX .framer-17xajw8 { bottom: 0px; flex: none; height: 565px; left: 62px; position: absolute; width: 31px; }", ".framer-gEjQX .framer-x16pe-container { flex: none; height: 48px; position: absolute; right: 26px; top: 40px; width: 88px; }", ".framer-gEjQX .framer-pxxjk1-container { flex: none; height: 48px; position: absolute; right: 27px; top: 96px; width: 88px; }", ".framer-gEjQX .framer-1kxvien-container { flex: none; height: 48px; position: absolute; right: 26px; top: 153px; width: 88px; }", ".framer-gEjQX .framer-vo3gnc-container { flex: none; height: 49px; position: absolute; right: 26px; top: 209px; width: 88px; }", ".framer-gEjQX .framer-vt6v17-container { flex: none; height: 49px; position: absolute; right: 26px; top: calc(50.17301038062286% - 49px / 2); width: 88px; }", ".framer-gEjQX .framer-1v9xnxk-container { bottom: 206px; flex: none; height: 49px; position: absolute; right: 28px; width: 87px; }", ".framer-gEjQX .framer-g1ncvh-container { bottom: 148px; flex: none; height: 49px; position: absolute; right: 28px; width: 87px; }", ".framer-gEjQX .framer-fesag0-container { bottom: 92px; flex: none; height: 48px; position: absolute; right: 27px; width: 88px; }", ".framer-gEjQX .framer-197h5e0-container { bottom: 36px; flex: none; height: 48px; position: absolute; right: 28px; width: 87px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 578
 * @framerIntrinsicWidth 166
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerOEx7GAlMi: React.ComponentType<Props> = withCSS(Component, css, "framer-gEjQX") as typeof Component;
export default FramerOEx7GAlMi;

FramerOEx7GAlMi.displayName = "side breadcrum bar";

FramerOEx7GAlMi.defaultProps = {height: 578, width: 166};

addFonts(FramerOEx7GAlMi, [{explicitInter: true, fonts: []}, ...TableOfContentNobsFonts], {supportsExplicitInterCodegen: true})